//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-164:_7372,_9225,_1268,_9884,_3144,_6052,_2132,_7432;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-164')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-164', "_7372,_9225,_1268,_9884,_3144,_6052,_2132,_7432");
        }
      }catch (ex) {
        console.error(ex);
      }