//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-164:_1180,_2444,_540,_368,_1516,_2257,_6588,_9548;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-164')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-164', "_1180,_2444,_540,_368,_1516,_2257,_6588,_9548");
        }
      }catch (ex) {
        console.error(ex);
      }